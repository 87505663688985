<template>
  <div class="tc-measure">
    <!-- 排序 -->
    <div class="sort">
      <el-button type="plain" plain @click="showQrBox" size="mini"
        >生成二维码</el-button
      >
      <!-- <el-divider direction="vertical"></el-divider>
      <el-button type="plain" plain>清除量表缓存</el-button>-->
      <el-divider direction="vertical"></el-divider>
      <el-button type="plain" plain @click="upSort" size="mini">排序</el-button>
      <el-divider direction="vertical" size="mini"></el-divider>
      <el-select
        v-model="queryMeasureClass"
        placeholder="请选择量表分类"
        size="mini"
      >
        <el-option
          :label="item.classify_name"
          :value="item.id"
          v-for="item in MeasureClass"
          :key="item.id"
        ></el-option>
      </el-select>
      <el-divider direction="vertical"></el-divider>
      <el-input v-model="query" size="mini"></el-input>
      <el-button type="plain" plain @click="queryMe" size="mini"
        >查询量表</el-button
      >
      <el-divider direction="vertical"></el-divider>
      <el-button type="plain" plain @click="reset" size="mini">重置</el-button>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        max-height="570"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
        row-key="id"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="60"
          align="center"
          v-model="checkBoxListener"
        ></el-table-column>

        <el-table-column
          prop="measure_title"
          label="量表名称"
          width="350"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="custom_name"
          label="自定义名称"
          width="350"
          align="center"
        >
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.custom_name">{{scope.row.custom_name}}</span>
          </template>-->
        </el-table-column>
        <el-table-column prop="department_name" label="量表类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.classify_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="evaluate_count"
          label="报告"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column prop="sort" label="排序" width="100" align="center">
          <template slot-scope="scope">
            <!-- <el-input v-bind="scope.row.sort" /> -->
            <el-input
              v-model="scope.row.sort"
              size="mini"
              maxlength="2"
              @blur="updateSort(scope.row)"
              onkeyup="value=value.replace(/[^\d]/g,'') "
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100" align="center">
          <template slot-scope="scope">
            <span
              :class="scope.row.status == 0 ? 'off' : 'no'"
              @click="updateStatus(scope.row)"
              >{{ scope.row.status | statusFilter }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="lookMeasure(scope.row)"
              >查看</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="editMeasure(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="delMeasures(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page" v-if="showPage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="size"
        :page-sizes="[15, 30, 50, 100, 200, 400]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 查看模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="look"
      width="50%"
      center
    >
      <div class="queryTable">
        <div id="pdfDom" >
        <div>
          <p style="color:#303133;font-size:18px;font-weight:700;line-height:24px;text-align:center;">{{look_measure_title}}</p>
          <p v-html="look_msg" class="measure-msg"></p>
          <p
            v-if="look_questions.length > 0"
            style="font-size: 16px; margin-top: 10px; color: black"
          >
            题目详情：
          </p>
        </div>
        <div v-for="item in look_questions" :key="item.id">
          <div class="queryTable-title " style=" color: #044d5a">
            {{
              item.question
            }}
          </div>
          <div
            class="queryTable-item"
            style=" margin-left: 0px"
            v-for="(item2, key2) in item.options"
            :key="key2"
          >
            {{
              item2
            }}

          </div>
        </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="look = false">关 闭</el-button>
        <el-button type="primary"  v-print="printObjNew" >导出</el-button>
      </span>
    </el-dialog>
    <!-- 编辑模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑量表信息"
      :visible.sync="edit"
      width="50%"
      center
    >
      <el-form ref="form" :model="form" label-width="110px" size="mini">
        <el-form-item label="量表名称">
          <span>{{ form.edit_MeasureName }}</span>
        </el-form-item>
        <el-form-item label="表单类型">
          <el-select v-model="form.edit_MeasureClass">
            <el-option
              :label="item.classify_name"
              :value="item.id"
              v-for="item in MeasureClass"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="自定义量表名称">
          <el-input v-model="form.edit_MeasureNameExplain"></el-input>
        </el-form-item>
        <el-form-item label="自定义答案说明">
          <el-input type="textarea" v-model="form.edit_UserDefined"></el-input>
        </el-form-item>
        <el-form-item label="图表样式">
          <el-select v-model="theme" placeholder="请选择">
            <el-option label="默认" :value="0"></el-option>
            <el-option
              label="（旧）单数据-SDS专用"
              :value="8"
              v-if="form.edit_MeasureName.indexOf('SDS') != -1"
            ></el-option>
            <el-option
              label="（新）单数据-SAS专用"
              :value="88"
              v-if="form.edit_MeasureName.indexOf('SAS') != -1"
            ></el-option>
            <el-option label="（旧）单数据-普通绿色仪表盘" :value="1"></el-option>
            <el-option label="（旧）多数据-渐变紫色折线图" :value="2"></el-option>
            <el-option label="（旧）多数据-渐变黄色网状圆盘" :value="3"></el-option>
            <el-option label="（旧）单数据-炫酷紫色仪表盘" :value="4"></el-option>
            <el-option
              label="（旧）多数据-五彩斑斓不规则圆盘-1"
              :value="5"
            ></el-option>
            <el-option
              label="（旧）多数据-五彩斑斓不规则圆盘-2"
              :value="6"
            ></el-option>
            <el-option label="（旧）单数据-渐变仪表盘" :value="7"></el-option>
            <el-option label="（旧）多数据-柱状和折线的结合" :value="9"></el-option>
            <el-option label="（旧）多数据-淡红峰形柱状图" :value="10"></el-option>
            <el-option label="（旧）多数据-炫酷圆环扇形图" :value="11"></el-option>
            <el-option label="（新）多数据-炫酷圆环扇形图" :value="24"></el-option>
            <el-option label="（新）单数据-刻度线范围图" :value="20"></el-option>
            <el-option label="（新）单数据-刻度线" :value="21"></el-option>
            <el-option label="（新）单数据-耳环图" :value="22"></el-option>
            <el-option label="（新）多数据-漂亮柱状图" :value="23"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="center">
        <img src="@/assets/images/them-1.png" alt v-if="theme == 1" />
        <img src="@/assets/images/them-2.png" alt v-if="theme == 2" />
        <img src="@/assets/images/them-3.png" alt v-if="theme == 3" />
        <img src="@/assets/images/them-4.png" alt v-if="theme == 4" />
        <img src="@/assets/images/them-5.png" alt v-if="theme == 5" />
        <img src="@/assets/images/them-6.png" alt v-if="theme == 6" />
        <img src="@/assets/images/them-7.png" alt v-if="theme == 7" />
        <img src="@/assets/images/them-8.png" alt v-if="theme == 8" />
        <img src="@/assets/images/them-8.png" alt v-if="theme == 88" />
        <img src="@/assets/images/them-9.png" alt v-if="theme == 9" />
        <img src="@/assets/images/them-10.png" alt v-if="theme == 10" />
        <img src="@/assets/images/them-11.png" alt v-if="theme == 24" />
        <img src="@/assets/images/them-11.png" alt v-if="theme == 11" />
        <!-- 新的 -->
        <img src="@/assets/images/them-250.png" alt v-if="theme == 21" />
        <img src="@/assets/images/them-251.png" alt v-if="theme == 20" />
        <img src="@/assets/images/them-252.png" alt v-if="theme == 22" />
        <img
          style="width: 100%; height: 200px"
          src="@/assets/images/them-253.png"
          alt
          v-if="theme == 23"
        />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edit = false">取 消</el-button>
        <el-button type="primary" @click="editMeasureInfo()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 二维码显示模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="生成量表二维码"
      :visible.sync="qrBox"
      width="40%"
      center
      :destroy-on-close="true"
    >
      <div class="qr">
        <div class="qr-show">
          <h2 @click="showQrQeury(1)">
            <span v-show="isqr1">隐藏</span>
            <span v-show="!isqr1">显示</span>二维码(可查看结果)
          </h2>
          <el-collapse-transition>
            <div id="qrcode" ref="qrcode" v-show="isqr1"></div>
          </el-collapse-transition>
        </div>
        <div class="qr-hide">
          <h2 @click="showQrQeury(2)">
            <span v-show="isqr2">隐藏</span>
            <span v-show="!isqr2">显示</span>二维码(不可查看结果)
          </h2>
          <el-collapse-transition>
            <div id="qrcode2" ref="qrcode2" v-show="isqr2"></div>
          </el-collapse-transition>
        </div>
      </div>
      <!-- <div class="departmenselet">
        所属部门：
        <el-select
          v-model="departmenid"
          placeholder="请选择"
          size="mini"
          @change="departmenselect"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            >{{ item.name }}</el-option
          >
        </el-select>
      </div> -->
      <div>
        
      </div>
      <div class="choose">
        <el-row>
          <el-col :span="12">
            <div>
              所属部门：
              <el-select
                v-model="departmenid"
                placeholder="请选择"
                size="mini"
                @change="departmenselect"
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  >{{ item.name }}</el-option
                >
              </el-select>
            </div>
          </el-col>
          <el-col :span="12">
            <div>
              过期日期：
              <el-date-picker
                size="mini"
                v-model="expiretime"
                type="date"
                placeholder="选择过期日期时间"
                @change="expiretimeselect"
                value-format="timestamp"
              >
              </el-date-picker>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 20px">
        下载说明：请点击 - 鼠标右键- 选择 - 图片另存为
      </div>
      <div v-if="this.isqr1" style="margin-top:20px">
        <span>可查看结果：</span><el-input v-model="isqr1url" style="width:60%" size="mini"></el-input>
      </div>
      <div v-if="this.isqr2" style="margin-top:20px">
        <span>不可查看结果：</span><el-input v-model="isqr2url" style="width:60%" size="mini"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="qrBox = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  loadMeasureList,
  updateMeasureInfo,
  getMeasureInfo,
  getMeasureClassifyList,
  deleteMeasure,
  getMeasureCode,
} from "@/api/evaluate.js";
import { loadDepartmentData } from "@/api/comm.js";
import { log } from "util";
import QRCode from "qrcodejs2";

export default {
  inject: ["reload"],
  data() {
    return {
      isqr1url:"",
      isqr2url:"",
      printObjNew:{
        id: "pdfDom",
        popTitle: "新谦雅心理CT系统v6.0",
      },
      //过期时间
      expiretime: "",
      showPage: true,
      loading: true,
      BoxLoading: true,
      // 二维码模态框参数
      isqr1: false,
      isqr2: false,
      qrBox: false,
      multipleSelection: [],
      tableData: [],
      // 页码
      page: 1,
      size: 15,
      // 总数
      total: 0,
      look: false,
      edit: false,
      // -----查看模态框数据----
      look_measure_title: "",
      look_describe: "",
      look_msg: "",
      look_questions: [],
      // -----编辑模态框数据-----
      form: {
        edit_MeasureName: "",
        edit_MeasureClass: "",
        edit_MeasureNameExplain: "",
        edit_UserDefined: "",
        //修改当前量表id
        uMeasureId: 0,
      },
      MeasureClass: [],
      // 查询
      query: "",
      order: "sort desc",
      queryMeasureClass: "",
      // 图表主题
      theme: "0",
      themeImg: "./img/them-1.png",
      // 二维码量表组标识
      measure_token: "",
      // 部门
      departmentList: [],
      departmenid: "",
      departmenName: "",
    };
  },
  created() {
    this.loadmeasureListData();
    // 获取报告类型
    // 获取量表分类
    getMeasureClassifyList({ page: 1, size: 9999 }).then((res) => {
      this.MeasureClass = res.data.data;
    });
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });

    //清除勾选状态
  },
  methods: {
    //选中状态监听
    checkBoxListener(val) {
      console.log(val);
    },

    // 控制可查看不可查看二维码的选择
    showQrQeury(val) {
      if (val == 1) {
        if (this.isqr1) {
          this.isqr1 = false;
        } else {
          this.isqr1 = true;
        }
        // console.log("this.isqr1",this.isqr1);
      } else {
        if (this.isqr2) {
          this.isqr2 = false;
        } else {
          this.isqr2 = true;
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
    handleSizeChange(val) {
      this.loading = true;
      this.size = val;
      this.loadmeasureListData();
    },
    // 页码监听
    handleCurrentChange(val) {
      // 根据page加载对应数据
      this.loading = true;
      this.page = val;
      this.loadmeasureListData();
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 查询
    queryMe() {
      this.page = 1;
      this.loading = true;
      this.showPage = false;
      this.loadmeasureListData();
      this.$nextTick(() => {
        this.showPage = true;
      });
    },
    // 加载列表
    loadmeasureListData() {
      let data = {
        page: this.page,
        size: this.size,
        order: this.order,
        condition: {
          "%-measure_title": this.query,
          classify_id: this.queryMeasureClass,
        },
      };
      loadMeasureList(data).then((res) => {
        if (res.code == 400200) {
          this.tableData = res.data.data;
          // this.total = res.data.total;
          this.total = res.data.total * 1;
          this.loading = false;
        }
      });
    },
    // 量表排序更新
    updateSort(row) {
      let data = {
        id: row.id,
        sort: row.sort,
      };
      updateMeasureInfo(row).then((res) => {
        if (res.code === 400200) {
          this.$message({
            message: "更新成功",
            type: "success",
          });
          this.loadmeasureListData();
        }
      });
    },
    // 量表状态
    updateStatus(row) {
      let data = row;
      let msg = "是否关闭该量表";
      if (data.status === 1) {
        msg = "是否关闭该量表";
      } else {
        msg = "是否开启该量表";
      }
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (data.status === 1) {
            data.status = 0;
          } else {
            data.status = 1;
          }
          updateMeasureInfo(data).then((res) => {
            if (res.code === 400200) {
              if (data.status === 0) {
                this.$message({
                  type: "success",
                  message: "已关闭!",
                });
              } else {
                this.$message({
                  type: "success",
                  message: "已开启!",
                });
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 查看模态框
    lookMeasure(row) {
      let { id } = row;
      this.look = true;
      this.look_measure_title = "";
      this.look_describe = "";
      this.look_msg = "";
      this.look_questions = [];
      this.BoxLoading = true;
      getMeasureInfo({ id, page: this.page }).then((res) => {
        if (res.code == 400200) {
          this.look_measure_title = res.data.measure_title;
          this.look_msg = res.data.msg;
          this.look_questions = res.data.questions;
          this.BoxLoading = false;
        }
      });
    },
    // 编辑模态框
    editMeasure(row) {
      this.edit = true;
      // 填充信息
      let {
        measure_title,
        custom_name,
        classify_name,
        custom_answer_desc,
        classify_id,
        id,
        status,
        theme,
      } = row;
      this.form.uMeasureId = id;
      this.form.edit_MeasureName = measure_title;
      this.form.edit_MeasureNameExplain = custom_name;
      this.form.edit_UserDefined = custom_answer_desc;
      this.form.edit_MeasureClass = classify_id;
      this.form.status = status;
      this.theme = theme;
    },
    // 编辑提交
    editMeasureInfo() {
      let data = {
        measure_title: this.form.edit_MeasureName,
        id: this.form.uMeasureId,
        custom_answer_desc: this.form.edit_UserDefined,
        custom_name: this.form.edit_MeasureNameExplain,
        classify_id: this.form.edit_MeasureClass,
        status: this.form.status,
        theme: this.theme,
      };
      updateMeasureInfo(data).then((res) => {
        if (res.code === 400200) {
          this.$message({
            message: "修改成功!",
            type: "success",
          });
          this.edit = false;
          this.loadmeasureListData();
        }
      });
    },
    // 数据排序
    upSort() {
      this.loading = true;
      if (this.order == "sort desc") {
        this.order = "sort";
      } else {
        this.order = "sort desc";
      }
      this.loadmeasureListData();
    },
    // 删除量表
    delMeasures(row) {
      this.$confirm("是否删除此量表?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMeasure({ id: row.id }).then((res) => {
            if (res.code === 400200) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
            }
            this.loadmeasureListData();
          });
        })
        .catch(() => {});
    },
    // 生成二维码
    qrcode() {
      // let meaids = this.multipleSelection.toString();
      // let url = window.location.protocol + "//" + window.location.host;
      let config = JSON.parse(localStorage.getItem("config"));
      let url = config.localhost;
      // console.log("过期日期选择",this.expiretime);
      let expiretimenew=null;
      if(this.expiretime){
          expiretimenew=this.expiretime / 1000
      }
      // console.log("expiretimenew",expiretimenew);
      let qrcode = new QRCode("qrcode", {
        width: 200, // 设置宽度，单位像素
        height: 200, // 设置高度，单位像素
        text: `${url}/index.html#/answer_content/answer_list?meaids=${this.measure_token}&meaidsshow=1&depId=${this.departmenid}&depName=${this.departmenName}&expire=${expiretimenew}`, // 设置二维码内容或跳转地址
      });
      this.isqr1url=`${url}/index.html#/answer_content/answer_list?meaids=${this.measure_token}&meaidsshow=1&depId=${this.departmenid}&depName=${this.departmenName}&expire=${expiretimenew}`;
      let qrcode2 = new QRCode("qrcode2", {
        width: 200, // 设置宽度，单位像素
        height: 200, // 设置高度，单位像素
        text: `${url}/index.html#/answer_content/answer_list?meaids=${this.measure_token}&meaidsshow=0&depId=${this.departmenid}&depName=${this.departmenName}&expire=${expiretimenew}`, // 设置二维码内容或跳转地址
      });
      this.isqr2url=`${url}/index.html#/answer_content/answer_list?meaids=${this.measure_token}&meaidsshow=0&depId=${this.departmenid}&depName=${this.departmenName}&expire=${expiretimenew}`;

    },
    // 显示二维码模态框
    showQrBox() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选量表",
          type: "warning",
        });
        return false;
      } else {
        getMeasureCode({ id: this.multipleSelection.toString() }).then(
          (res) => {
            if (res.code == 400200) {
              this.qrBox = true;
              this.$nextTick(() => {
                this.measure_token = res.data;
                this.qrcode();
              });
            }
          }
        );
      }
    },
    // 重置
    reset() {
      this.page = 1;
      this.query = "";
      this.queryMeasureClass = "";
    },
    // 二维码，部门选择
    departmenselect() {
      this.qrBox = false;
      this.departmentList.forEach((v) => {
        if (v.id == this.departmenid) {
          this.departmenName = v.name;
          return;
        }
      });
      this.$nextTick(() => {
        this.qrBox = true;
        setTimeout(() => {
          this.qrcode();
        }, 300);
      });
    },
    //二维码，过期时间选择
    expiretimeselect(e) {
      // 二维码模态框
      this.qrBox = false;
      this.$nextTick(() => {
        this.qrBox = true;
        setTimeout(() => {
          // 生成二维码
          this.qrcode();
        }, 300);
      });
    },
  },
  filters: {
    statusFilter(val) {
      return val == 1 ? "启用" : "关闭";
    },
  },
  watch: {
    theme: function () {
      // this.themeImg = ` @/assets/images/them-.png`;
      // img/them-1.png
      // this.themeImg = `./img/them-${this.theme}.png`;
      // console.log(this.themeImg);
    },
  },
};
</script>
<style lang="less">
.tc-measure {
  width: 100%;
  min-width: 1300px;
  // 头部
  .header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 180px;
          list-style: none;
          padding: 0 30px;
          text-align: center;
          img {
            width: 100%;
            // pointer-events: none;
          }
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .del {
        width: 120px;
        cursor: pointer;
      }
      .refresh {
        width: 120px;
        cursor: pointer;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  //   排序
  .sort {
    margin-top: 20px;
    height: 55px;
    background-color: rgb(#d9f2f7);
    display: flex;
    align-items: center;
    padding-left: 10px;
    .el-divider--vertical {
      height: 2em;
    }
    .el-button {
      background: transparent;
      color: rgb(#044d5a);
      font-size: 15px;
      border: 1px solid rgb(182, 226, 235);
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .el-input {
      width: 150px;
      margin-right: 5px;
    }
    .el-input__inner {
      width: 150px;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 570px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no,
    .off {
      cursor: pointer;
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .qr {
    display: flex;
    justify-content: space-around;
    text-align: center;
    h2 {
      margin-bottom: 10px;
      font-size: 16px;
      cursor: pointer;
    }
    .qr-show {
      color: green;
    }
    .qr-hide {
      color: red;
    }
  }
  .departmenselet {
    padding-top: 30px;
  }
  .choose {
    margin-top: 20px;
  }
  // 模态框
  .el-dialog__wrapper {
    .msgLoad {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
    .measure-msg {
      color: black;
    }
    .queryTable {
      max-height: 500px;
      overflow: auto;
      table {
        width: 100%;
        transition: all 0.25s;
        &:hover {
          background: rgb(236, 235, 211);
        }
      }
      .queryTable-title {
        font-weight: 700;
        font-size: 14px;
        color: #044d5a;
      }
      .queryTable-item {
        // margin-left: 30px;
        
        color: rgb(48, 47, 47);
      }
    }
  }
}
</style>